import React, { Component } from "react";

const About = () => {
  return (
    <div className="mainContent">
      <p>Hello, my name is Dwight.</p>
      <p>
        I'm California born and bred - from the coast to the mountains! I've
        lived in Santa Monica, Nevada City, Arcata, Sacramento, San Francisco,
        Venice and (currently) Playa del Rey.
      </p>
      <p>
        My passion since high school to college and even to today has been
        media. Usually behind the scenes. From editor, production, writing,
        coding or snapping pictures. I'm obsessed and love each step.
      </p>
      <p>
        Currently, I'm focusing my time on coding front end websites and SPA
        apps. Like, this page you're looking at is written in JSX/React which
        then gets processed into javascript. How amazing.
      </p>
    </div>
  );
};

export default About;
