import React, { Component } from "react";

const Home = () => {
  return (
    <div className="mainContent">
      <img
        src={process.env.PUBLIC_URL + "/portfoliome600x600.gif"}
        style={{
          display: "block",
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
        }}
        alt="Hi, I'm Dwight."
      />
    </div>
  );
};

export default Home;

// {
//   "date_range": "Jan 20 - Feb 18",
//   "current_date": "October 31, 2022",
//   "description": "It's not your imagination. They're trying to manipulate you into doing what they want you to do, and if subtle tactics don't work, they'll try something more obvious. Better nip it in the bud.",
//   "compatibility": "Libra",
//   "mood": "Smart",
//   "color": "Shadow Black",
//   "lucky_number": "84",
//   "lucky_time": "9pm"
// }
