import React, { Component } from "react";

const Portfolio = () => {
  const img_portfolio_header = {
    display: "block",
    width: "100%",
    maxWidth: "900px",
    margin: "0 auto",
    boxSizing: "border-box",
  };

  return (
    <div className="mainContent">
      <h2>Guitar Center</h2>
      <p>
        During the last few years of work (April 2018 ~ August 2022) - if I was
        at the computer - I was coding for the national instrument e-commerce
        store: Guitar Center. First, I was hired as an{" "}
        <strong>HTML Email Coder</strong> for the marketing department. After 6
        months, I was promoted to coding landing pages as a{" "}
        <strong>Front End Developer</strong> for the IT Department.
      </p>
      <p>
        After 4 years and thousands of support tickets , I decided it was time
        to leave Guitar Center for some new inspiration. I found a code program
        in Manhattan Beach, CA that offered a 1-on-1 tutor with a senior
        developer. For weeks, I sat with my tutor and we would go over SPA
        technologies. From vanilla javascript to backend tech like Node.js,
        Express, Vue.js, React, Firebase, etc.
      </p>

      <br />
      <h2>Portfolio time travel: 2009-2018</h2>

      <img
        src={process.env.PUBLIC_URL + "/portfoliographicdesign912x396.png"}
        id="img_portfolio_graphicDesign"
        style={img_portfolio_header}
        alt="Graphic Design Portfolio header"
      />
      <h3>Graphic Design / Photography</h3>
      <p>
        In the early years, to edit my school's newspaper - I was mostly using
        QuarkXPress and photoshop. Then in college I expanded my Adobe skills
        with InDesign and Illustrator. I love Illustrator! It was love at first
        click.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/portfolioprint912x396.png"}
        id="img_portfolio_print"
        style={img_portfolio_header}
        alt="Print Portfolio header"
      />
      <h3>Print Materials</h3>
      <p>
        I've been printing materials since I had to split zip disks up to fit
        all the asset exports! I then had to drive to the printer with those zip
        disks. We're talking Napster days. Since then, I've used various local
        printers and online services. I speak CMYK and RGB. I love the committal
        of printing. I've worked on various newspaper teams as well as magazines
        in college. I've design prints for two political campaigns and countless
        small businesses.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/portfoliowebsite912x396.png"}
        id="img_portfolio_websites"
        style={img_portfolio_header}
        alt="Websites Portfolio header"
      />
      <h3>Websites / Email / Apps</h3>
      <p>
        Websites started almost by accident. After college, my first gig was to
        create the branding and marketing for a local political campaign. I used
        a built-in program on my Apple computer that spit out one page. Somehow,
        I hacked together a website. Each site I built after that improved and I
        slowly taught myself how to be a real website developer. Wordpress
        custom theme development was my main focus for years. Then came Ruby on
        Rails and JavaScript.Today, I'm still focused on JavaScript. I like that
        unlike Ruby on Rails, it's not going to be going anywhere anytime soon.
        Like, it's only 3 components that the browser understands: HTML, CSS and
        JavaScript. Without knowing the basics, all these other "cheats" or
        frameworks are not as powerful. Developers need to know the basics to
        really become senior developers; I know this because I consider myself
        an intermediate developer!{" "}
      </p>
      <h2>Coding HTML Emails</h2>
      <p>
        I've completed two "bootcamps" for coding but nothing was as intense and
        grueling as coding HTML emails for a national brand like Guitar Center.
        Luckily, I only had to do that job for 6 months before I was promoted to
        the website team. However, it was in coding email where I learned how to
        see code like Neo in The Matrix.
      </p>
      <br />

      <h2>College & Pre-College</h2>
      <p>
        I graduated with a BA in Journalism which was cool.. inevitable really.
        My media journey started long before college. Freshman year of high
        school was probably the start or even in the 8th grade when I picked out
        the newspaper class as my elective! I was a photographer, writer,
        interviewer, editor, designer and in senior year, the editor-in-chief of
        the school newspaper.
      </p>
      <p>
        Other than my newspaper class (which I took all 4 years of high school)
        - I had two media internships. First internship was as a writer and
        designer at the local neighborhood newspaper, <i>The Union</i>. I also
        had an internship at the local public access television station. Later
        in college, I had another internship at the local NBC affiliate where I
        was lucky enough to run the cameras for the nightly live news coverage.
        Also, during college - I was able to edit my own radio show, design
        pages for the school's magazine as well as take raw photos.
      </p>
    </div>
  );
};

export default Portfolio;
