import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home.js";
import About from "./components/About.js";
import Portfolio from "./components/Portfolio.js";

// import Nav from "./components/Nav.js";

function App() {
  return (
    <div className="App">
      <nav className="Nav">
        <Link to="/">
          <header>
            <h1>D-W-I-G-H-T</h1>
          </header>
        </Link>
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/dwightgnjohnson/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Dwightgnjohnson"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
    </div>
  );
}

export default App;
